import {produce} from 'immer';
import {
  ARTICLES_LOAD,
  ARTICLES_LOAD_SUCCESS,
  ARTICLES_LOAD_ERROR,
  ARTICLES_PORT_CONFIGUATION_LOAD,
  ARTICLES_PORT_CONFIGUATION_LOAD_SUCCESS,
  ARTICLES_PORT_CONFIGUATION_LOAD_ERROR,
  ACCESSORIES_LOAD,
  ACCESSORIES_LOAD_SUCCESS,
  ACCESSORIES_LOAD_ERROR,
  XPCS_LOAD,
  XPCS_LOAD_SUCCESS,
  XPCS_LOAD_ERROR,
  ARTICLES_FILTERS_CONNECTION_FILTERS_CHANGE,
  PRODUCT_SELECTOR_FILTERS_INIT,
  PRODUCT_SELECTOR_FILTERS_CHANGE,
} from "../constants/actionTypes";

const defaultSelections = {
  factorySelections: [
    {
      key: 'SI',
      isSelected: true
    },
    {
      key: 'SSK',
      isSelected: true
    },
    {
      key: 'SCN',
      isSelected: true
    },
    {
      key: 'SMY',
      isSelected: true
    },
    {
      key: 'SNA',
      isSelected: true
    }
  ],
  materialSelections: [
    {
      key: 'MC',
      isSelected: true
    },
    {
      key: 'NC',
      isSelected: true
    },
    {
      key: 'NSC',
      isSelected: true
    },
    {
      key: 'SC',
      isSelected: true
    },
    {
      key: 'SN',
      isSelected: true
    },
    {
      key: 'SPF',
      isSelected: true
    },
    {
      key: 'SPN',
      isSelected: true
    },
    {
      key: 'SPS',
      isSelected: true
    },
    {
      key: 'CSE',
      isSelected: true
    },
    {
      key: 'CSN',
      isSelected: true
    },
    {
      key: 'CTE',
      isSelected: true
    },
    {
      key: 'CTN',
      isSelected: true
    },
    {
      key: 'SSN',
      isSelected: true
    },
    {
      key: 'STE',
      isSelected: true
    },
    {
      key: 'STN',
      isSelected: true
    }
  ],
  pressureSelections: [
    {
      key: 'D',
      isSelected: true
    },
    {
      key: 'E',
      isSelected: true
    },
    {
      key: 'F',
      isSelected: true
    },
    {
      key: 'H',
      isSelected: true
    },
    {
      key: 'U',
      isSelected: true
    },
    {
      key: 'UT',
      isSelected: true
    },
    {
      key: 'H1',
      isSelected: true
    },
    {
      key: 'S',
      isSelected: true
    },
    {
      key: 'M',
      isSelected: true
    },
    {
      key: 'L',
      isSelected: true
    },
    {
      key: 'P',
      isSelected: true
    },
    {
      key: 'T',
      isSelected: true
    },
    {
      key: 'Y',
      isSelected: true
    }
  ],
  warehouseSelections: [
    {
      key: 'CEW',
      isSelected: false
    },
    {
      key: 'CCW',
      isSelected: false
    },
    {
      key: 'ACW',
      isSelected: false
    },
    {
      key: 'ICW',
      isSelected: false
    },
    {
      key: 'NAW',
      isSelected: false
    },
    {
      key: 'BCW',
      isSelected: false
    },
    {
      key: 'RS',
      isSelected: false
    }
  ]
};

export const filterDefaults= {
  F1Selection: [],
  F2Selection: [],
  F3Selection: [],
  F4Selection: [],
  F5Selection: [],
  F6Selection: [],
  P1Selection: [],
  P2Selection: [],
  P3Selection: [],
  P4Selection: [],
  P5Selection: [],
  P6Selection: [],
  showOnlyStandardArticles: false,
  showOnlyConfigs: false,
  ...defaultSelections
};

// The initial state of the App
export const initialState = {
  availableArticles: [],
  articles: {
    loading: false,
    error: false,
    repos: false,
    articles: [],
    portInfos: []
  },
  accessories: {
    loading: false,
    error: false,
    accessories: []
  },
  xpcs: {
    loading: false,
    error: false,
    xpcs: [],
  },
  filters: filterDefaults
};

const productSelectorReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case "":
        draft.test = "test";
        break;
      case ARTICLES_LOAD:
        draft.articles.loading = true;
        draft.articles.error = false;
        draft.articles.articles = [];
        break;
      case ARTICLES_LOAD_SUCCESS:
        draft.articles.loading = false;
        draft.articles.error = false;
        draft.articles.articles = action.value;
        break;
      case ARTICLES_LOAD_ERROR:
        draft.articles.loading = false;
        draft.articles.error = action.error;
        draft.articles.articles = [];
        break;
      case PRODUCT_SELECTOR_FILTERS_INIT:
        draft.filters.factorySelections = action.selectedFilters.factorySelections;
        draft.filters.materialSelections = action.selectedFilters.materialSelections;
        draft.filters.pressureSelections = action.selectedFilters.pressureSelections;
        draft.filters.warehouseSelections = action.selectedFilters.warehouseSelections;
        break;
      case PRODUCT_SELECTOR_FILTERS_CHANGE:
        draft.filters = action.selectedFilters;
        break;
      case ARTICLES_FILTERS_CONNECTION_FILTERS_CHANGE:
        draft.filters[action.connectionTypeName] = action.selectedConnectionFilters;
        break;
      case ARTICLES_PORT_CONFIGUATION_LOAD: {
        const newArticles = [...state.articles.articles];
        const articleNumberIndex = newArticles.findIndex(x => action.localId === x.localId);
        const newArticle = {...newArticles[articleNumberIndex]};
        newArticle.ports = {
          loading: true,
          error: false,
          portInfo: {},
          portConfiguration: []
        };
        newArticles[articleNumberIndex] = newArticle;
        draft.articles.articles = newArticles;
      }
        break;
      case ARTICLES_PORT_CONFIGUATION_LOAD_SUCCESS: {
        let newArticles = [...state.articles.articles];
        let articleNumberIndex = newArticles.findIndex(x => action.localId === x.localId);
        const newArticle = {...newArticles[articleNumberIndex]};
        newArticle.ports = {
          loading: false,
          error: false,
          portInfo: action.portInfo,
          portConfiguration: action.portConfiguration
        };
        newArticles[articleNumberIndex] = newArticle;
        draft.articles.articles = newArticles;
      }
        break;
      case ARTICLES_PORT_CONFIGUATION_LOAD_ERROR: {
        let newArticles = [...state.articles.articles];
        let articleNumberIndex = newArticles.find(x => action.localId === x.localIdNumber);
        const newArticle = {...newArticles[articleNumberIndex]};
        newArticle.ports = {
          loading: false,
          error: action.error,
          portInfo: {},
          portConfiguration: []
        };
        newArticles[articleNumberIndex] = newArticle;
        draft.articles.articles = newArticles;
      }
        break;
      case ACCESSORIES_LOAD:
        draft.accessories = {
          loading: true,
          error: false,
          accessories: []
        };
        break;
      case ACCESSORIES_LOAD_SUCCESS:
        draft.accessories = {
          loading: false,
          error: false,
          accessories: action.value
        };
        break;
      case ACCESSORIES_LOAD_ERROR:
        draft.accessories = {
          loading: false,
          error: action.error,
          accessories: []
        };
        break;
      case XPCS_LOAD:
        draft.xpcs.loading = true;
        draft.xpcs.error = false;
        draft.xpcs.xpcs = [];
        break;
      case XPCS_LOAD_SUCCESS:
        draft.xpcs.loading = false;
        draft.xpcs.error = false;
        draft.xpcs.xpcs = action.value;
        break;
      case XPCS_LOAD_ERROR:
        draft.xpcs.loading = false;
        draft.xpcs.error = action.error;
        draft.xpcs.xpcs = [];
        break;
    }
  });

export default productSelectorReducer;
