import React, {memo, Suspense, useCallback} from 'react';
import PropTypes from 'prop-types';
import '../../components/calculation/inputForms/inputs.scss';
import UnitSystemProvider from '../../components/calculation/inputForms/unitSystem/UnitSystemProvider';
import {
  AHRI,
  SinglePhase,
  SinglePhaseLowLMTD,
  SinglePhaseDual,
  Condenser,
  CondenserDual,
  CondenserHeatPump,
  Evaporator,
  EvaporatorDual,
  EvaporatorHeatPump,
  LiquidEvaporator,
  Cascade,
  TwoStage,
  AirDryer,
  DistrictEnergy, Hypertwain
} from '../../constants/calculationTypes';
import LoadingIndicator from '../../components/LoadingIndicator';
import HypertwainInputs from "../../components/calculation/inputForms/HypertwainInputs";

const AHRIInputs = React.lazy(() => import('../../components/calculation/inputForms/AHRIInputs'));
const SinglePhaseInputs = React.lazy(() => import('../../components/calculation/inputForms/SinglePhaseInputs'));
const EvaporatorInputs = React.lazy(() => import('../../components/calculation/inputForms/EvaporatorInputs'));
const LiquidEvaporatorInputs = React.lazy(() => import('../../components/calculation/inputForms/LiquidEvaporatorInputs'));
const EvaporatorDualInputs = React.lazy(() => import('../../components/calculation/inputForms/EvaporatorDualInputs'));
const CondenserInputs = React.lazy(() => import('../../components/calculation/inputForms/CondenserInputs'));
const CondenserDualInputs = React.lazy(() => import('../../components/calculation/inputForms/CondenserDualInputs'));
const SinglePhaseDualInputs = React.lazy(() => import('../../components/calculation/inputForms/SinglePhaseDualInputs'));
const CascadeInputs = React.lazy(() => import('../../components/calculation/inputForms/CascadeInputs'));
const AirDryerInputs = React.lazy(() => import('../../components/calculation/inputForms/AirDryerInputs'));
const TwoStageInputs = React.lazy(() => import('../../components/calculation/inputForms/TwoStageInputs'));
const DistrictEnergyInputs = React.lazy(() => import('../../components/calculation/inputForms/DistrictEnergyInputs'));


const CalculationInputs = ({  calculationData, onChangeInputs, onSetInputsValidity, loadCalculationInputsHeatExchangers, hideButtons }) => {

  const handleCalculationMethodChange = useCallback((value) => {
    onChangeInputs([{fieldName: "calculationMethod", value: value}]);
  }, [onChangeInputs]);

  let calculationInputsFormComponent = null;
  switch(calculationData.userCalculationInputs.calculationType.toLowerCase())
  {
    case AHRI:
      calculationInputsFormComponent = (<AHRIInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} />);
      break;
    case SinglePhase:
    case SinglePhaseLowLMTD:
      calculationInputsFormComponent = <SinglePhaseInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} hideButtons={hideButtons} />;
      break;
    case SinglePhaseDual:
      calculationInputsFormComponent = (<SinglePhaseDualInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} hideButtons={hideButtons} />);
      break;
    case Evaporator:
    case EvaporatorHeatPump:
      calculationInputsFormComponent = (<EvaporatorInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} hideButtons={hideButtons} />);
      break;
    case LiquidEvaporator:
      calculationInputsFormComponent = (<LiquidEvaporatorInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} hideButtons={hideButtons} />);
      break;
    case EvaporatorDual:
      calculationInputsFormComponent = (<EvaporatorDualInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} hideButtons={hideButtons} />);
      break;
    case Condenser:
    case CondenserHeatPump:
      calculationInputsFormComponent = (<CondenserInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} hideButtons={hideButtons} />);
      break;
    case CondenserDual:
      calculationInputsFormComponent = (<CondenserDualInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} hideButtons={hideButtons} />);
      break;
    case Cascade:
      calculationInputsFormComponent = (<CascadeInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} hideButtons={hideButtons} />);
      break;
    case AirDryer:
      calculationInputsFormComponent = (<AirDryerInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} />);
      break;
    case TwoStage:
      calculationInputsFormComponent = (<TwoStageInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} />);
      break;
    case DistrictEnergy:
      calculationInputsFormComponent = (<DistrictEnergyInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} />);
      break;
    case Hypertwain:
      calculationInputsFormComponent = (<HypertwainInputs calculationData={calculationData} onChange={onChangeInputs} onCalcMethodChange={handleCalculationMethodChange} setFormValidity={onSetInputsValidity} onReloadHeatExchangers={loadCalculationInputsHeatExchangers} />);
      break;
    default:
      throw new Error('Unknown calculation type for calculation inputs component');
  }

  return (
    <UnitSystemProvider>
      <Suspense fallback={<LoadingIndicator/>}>
        {calculationInputsFormComponent}
      </Suspense>
    </UnitSystemProvider>
  );
};

CalculationInputs.propTypes = {
  calculationData: PropTypes.object.isRequired,
  onChangeInputs: PropTypes.func.isRequired,
  onSetInputsValidity: PropTypes.func.isRequired,
  loadCalculationInputsHeatExchangers: PropTypes.func.isRequired,
  hideButtons: PropTypes.bool
};

export default memo(CalculationInputs);
