export const SinglePhase = 'singlephase';
export const SinglePhaseLowLMTD = 'singlephaselowlmtd';
export const SinglePhaseDual = 'singlephasedual';
export const Condenser = 'condenser';
export const CondenserDual = 'condenserdual';
export const CondenserHeatPump = 'nhpcondenser';
export const Evaporator = 'evaporator';
export const EvaporatorDual = 'evaporatordual';
export const EvaporatorHeatPump = 'nhpevaporator';
export const LiquidEvaporator = 'liquidevaporator';
export const Cascade = 'cascade';
export const SinglePhaseTwoStacks = 'singlephasetwostacks';
export const TwoStage = 'twostage';
export const AirDryer = 'airdryer';
export const DistrictEnergy = 'districtenergy';
export const AHRI = 'ahri';
export const Hypertwain = 'hypertwain';

export const AllCalculationTypes = [
  SinglePhase,
  SinglePhaseLowLMTD,
  SinglePhaseDual,
  Condenser,
  CondenserDual,
  CondenserHeatPump,
  Evaporator,
  EvaporatorDual,
  EvaporatorHeatPump,
  LiquidEvaporator,
  Cascade,
  TwoStage,
  AirDryer,
  DistrictEnergy,
  AHRI,
  Hypertwain
];

export const calculationTypesNames = {
    [SinglePhase]: 'Single phase',
    [SinglePhaseLowLMTD]: 'Single phase (low LMTD)',
    [SinglePhaseDual]: 'Single phase dual',
    [Condenser]: 'Condenser',
    [CondenserDual]: 'Condenser dual',
    [CondenserHeatPump]: 'Condenser heat pump',
    [Evaporator]: 'Evaporator',
    [EvaporatorDual]: 'Evaporator dual',
    [EvaporatorHeatPump]: 'Evaporator heat pump',
    [LiquidEvaporator]: 'Liquid evaporator',
    [Cascade]: 'Cascade',
    [TwoStage]: 'Two stage',
    [AirDryer]: 'Air dryer',
    [DistrictEnergy]: 'District energy',
    [AHRI]: 'AHRI',
    [Hypertwain]: 'Hypertwain',
};
