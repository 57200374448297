import React from "react";
import PropTypes from 'prop-types';
import {Stack} from '@mui/material';
import MessageRow from './MessageRow';

const Messages = ({ messages }) => {
  if (messages && messages.length > 0) {
    return (
      <Stack direction="column">
        {messages.map((m, index) => {
          return (
            <div key={index}>
              <MessageRow message={m}/>
            </div>);
        })}
      </Stack>
    );
  } else {
    return null;
  }
};

Messages.propTypes = {
  messages: PropTypes.array,
};

export default React.memo(Messages);
