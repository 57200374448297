import React from 'react';
import {warningHelps as ddd, WarningHelp} from "./validation_error_codes";
import warningsData, { Warning } from './warnings';
import {Typography} from "@mui/material";
import {Box} from "@mui/system";


interface Props {
  code: string;
}

const findWarning = (code: string) => {

  if (!code) return null;

  const selectedSlug = ddd.find(item => item.Code === code);
  if (selectedSlug) {
    const warning = warningsData.find(w => w.slug === selectedSlug.Keyword);
    if (warning) {
      return warning;
    }
  }

  return null;
};

export const existsWarning = (code: string) => {
  const r = findWarning(code);
  return r;
};

const ItemComponent: React.FC<Props> = ({ code }) => {
  const warning: Warning = findWarning(code);

  if (!warning) {
    return <div>No item found</div>;
  }


  return (
    <Box sx={{ color: 'text.primary' }}>
      <Typography variant="body1">{warning.title}</Typography>
      <div dangerouslySetInnerHTML={{ __html: warning.rawHTML}}></div>
    </Box>
  );
};

export default ItemComponent;
