import React, {memo, useCallback} from 'react';
import {
  SinglePhase,
  SinglePhaseDual,
  Condenser,
  CondenserDual,
  CondenserHeatPump,
  Evaporator,
  EvaporatorDual,
  EvaporatorHeatPump,
  LiquidEvaporator,
  Cascade,
  TwoStage,
  AirDryer,
  DistrictEnergy,
  AHRI,
  Hypertwain, SinglePhaseLowLMTD
} from '../../../constants/calculationTypes';
import FilterSelectPanelBase from "../filters/FilterSelectPanelBase";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { makeSelectFilters } from 'selectors/calculationSelectors';

const FilterInputsBase = (props) => {
  const { calculationType, changeFilters } = props;

  const onChangeFilters = useCallback(changeFilters, [changeFilters]);

  // console.log('.... filters .... ', props)

  switch(calculationType) {
    case SinglePhase:
    case SinglePhaseLowLMTD:
    case SinglePhaseDual:
    case Evaporator:
    case LiquidEvaporator:
    case EvaporatorHeatPump:
    case EvaporatorDual:
    case Condenser:
    case CondenserHeatPump:
    case CondenserDual:
    case Cascade:
    case AHRI:
      return (<FilterSelectPanelBase
                calculationType={calculationType}
                filters={props.filters}
                filterSettings={props.filterSettings}
                changeFilters={onChangeFilters}
      />);
    case AirDryer:
    case TwoStage:
    case DistrictEnergy:
    case Hypertwain:
      return null;
    default:
      throw "FilterInputs not implemented calculation type";
  }
};

FilterInputsBase.propTypes = {
  calculationType: PropTypes.string.isRequired,
  filterSettings: PropTypes.object,
  filters: PropTypes.object,
  changeFilters: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    filters: makeSelectFilters()(state),
  };
};

const mapDispatchToProps = () => {
  return {
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FilterInputsBase);
