export interface WarningHelp {
  Code: string;
  Keyword: string;
}

export const warningHelps: WarningHelp[] = [
  { "Code": "SPC001", "Keyword": "SP_PortVel" },
  { "Code": "SPC002", "Keyword": "SP_PortVel" },
  { "Code": "SPC006", "Keyword": "SP_MinWallTemp" },
  { "Code": "SPC007", "Keyword": "SP_MinWallTemp" },
  { "Code": "SPC004", "Keyword": "SP_CalExceedMax" },
  { "Code": "SPC005", "Keyword": "SP_CalExceedMaxP" },
  { "Code": "SPC019", "Keyword": "SP_CalExceedMaxP" },
  { "Code": "SPC024", "Keyword": "PSW_InletTempDiffMoreThan100k" },
  { "Code": "PSW002", "Keyword": "SP_MaxPlatesNum" },
  { "Code": "", "Keyword": "SP_sp2pUnits" },
  { "Code": "PSW027", "Keyword": "SP_PortVelocityTooHigh" },
  { "Code": "PSW012", "Keyword": "SP_DesignAtSmallLMTD" },
  { "Code": "PSW029", "Keyword": "SP_Oil_Heat_Capacity" },
  { "Code": "CON001", "Keyword": "Cond_PDInCond" },
  { "Code": "CON002", "Keyword": "Cond_InPortVel" },
  { "Code": "CON003", "Keyword": "Cond_PortVelSec" },
  { "Code": "CON004", "Keyword": "Cond_InChanVel" },
  { "Code": "CON005", "Keyword": "Cond_InPortRec" },
  { "Code": "CON006", "Keyword": "Cond_Maldistribution" },
  { "Code": "CON007", "Keyword": "Cond_DoubleVap" },
  { "Code": "CON008", "Keyword": "Cond_PDForGasFlow" },
  { "Code": "CON009", "Keyword": "Cond_VHighHeat" },
  { "Code": "14", "Keyword": "Cond_ForB60HL" },
  { "Code": "PSW020", "Keyword": "Cond_HeatFluxNotVerified" },
  { "Code": "PSW004", "Keyword": "Cond_CondToHeatPump" },
  { "Code": "PSW028", "Keyword": "Cond_SmallTempPinch" },
  { "Code": "15", "Keyword": "Eva_VapPD" },
  { "Code": "VAP001", "Keyword": "Eva_Thermosyphon" },
  { "Code": "VAP002", "Keyword": "Eva_SWEPNotRecBTypeEva" },
  { "Code": "VAP003", "Keyword": "Eva_PartialFilmBoiling" },
  { "Code": "VAP004", "Keyword": "Eva_CompleteFilmBoil" },
  { "Code": "VAP005", "Keyword": "Eva_LowVapP" },
  { "Code": "VAP006", "Keyword": "Eva_HighExitPortPD" },
  { "Code": "VAP007", "Keyword": "Eva_VapMaldistrib" },
  { "Code": "VAP009", "Keyword": "Eva_PortVelSecSide" },
  { "Code": "VAP010", "Keyword": "Eva_ExitChanVelocity" },
  { "Code": "21", "Keyword": "Eva_PDInVRing" },
  { "Code": "VAP011", "Keyword": "Eva_MaldistribOnsecSide" },
  { "Code": "VAP012", "Keyword": "Eva_VaporVelocity" },
  { "Code": "VAP013", "Keyword": "Eva_TooSmallTemp" },
  { "Code": "VAP014", "Keyword": "Eva_Distribution" },
  { "Code": "VAP015", "Keyword": "Eva_PDInVRing" },
  { "Code": "VAP016", "Keyword": "Eva_selectedHE" },
  { "Code": "VAP017", "Keyword": "Eva_SuperheatLess" },
  { "Code": "VAP018", "Keyword": "Eva_highHeatFlux" },
  { "Code": "VAP019", "Keyword": "Eva_MinWallTempOnSec" },
  { "Code": "VAP020", "Keyword": "Eva_CurPerformance" },
  { "Code": "PSW028", "Keyword": "Eva_SmallTempPinch" },
  { "Code": "27", "Keyword": "Eva_PortVelocity" },
  { "Code": "28", "Keyword": "Eva_OutletRefriger" },
  { "Code": "PSW018", "Keyword": "Eva_PerfectDistribution" },
  { "Code": "PSW023", "Keyword": "Eva_NotRecommendDesign" },
  { "Code": "PSW003", "Keyword": "PSW_LowTempHighNoP" },
  { "Code": "PSW005", "Keyword": "PSW_NoDesignWithLowTemp" },
  { "Code": "PSW006", "Keyword": "PSW_DP310" },
  { "Code": "PSW007", "Keyword": "PSW_HeatFluxOutsideRange" },
  { "Code": "PSW010", "Keyword": "PSW_HightPDsecondarySide" },
  { "Code": "PSW015", "Keyword": "PSW_PerformanceOnlyWithConeConn" },
  { "Code": "PSW016", "Keyword": "PSW_SuperHeatLessThan5" },
  { "Code": "PSW017", "Keyword": "PSW_PerformanceOnlyForWaterConnections" },
  { "Code": "PSW024", "Keyword": "PSW_DesignWithNotLowTemp" },
  { "Code": "PSW030", "Keyword": "PSW_CrossConnected" },
  { "Code": "WarNull", "Keyword": "warnings" },
  { "Code": "lblFluids1", "Keyword": "fluidProperties" },
  { "Code": "lblFluids2", "Keyword": "fluidProperties" },
  { "Code": "lblValueCoCurrent", "Keyword": "" },
  { "Code": "lblExchangers", "Keyword": "" },
  { "Code": "PSW021", "Keyword": "PSW_B185" },
  { "Code": "PSW013", "Keyword": "PSW_B30" },
  { "Code": "PSW025", "Keyword": "PSW_greaterLside" },
  { "Code": "PSW022", "Keyword": "PSW_B60refrigerant" },
  { "Code": "PrimaryFluid", "Keyword": "fluidProperties" },
  { "Code": "SecondaryFluid", "Keyword": "fluidProperties" },
  { "Code": "StandardLoad_CoCurrent_Lbl", "Keyword": "ahri" },
  { "Code": "StandardLoad_Heatexchanger_Lbl", "Keyword": "" },
  { "Code": "StandardLoad_HeatLoad_Lbl", "Keyword": "fluidProperties" },
  { "Code": "StandardLoad_InTemp1_Lbl", "Keyword": "warnings" },
  { "Code": "StandardLoad_OutTemp1_Lbl", "Keyword": "warnings" },
  { "Code": "StandardLoad_Flow1_Lbl", "Keyword": "" },
  { "Code": "StandardLoad_MDP1_Lbl", "Keyword": "" },
  { "Code": "StandardLoad_Passes_Lbl", "Keyword": "" },
  { "Code": "StandardLoad_Plates_Lbl", "Keyword": "" },
  { "Code": "RefToAir_Plates_Lbl", "Keyword": "" },
  { "Code": "StandardLoad_Channel_Lbl", "Keyword": "" },
  { "Code": "StandardLoad_Chan11_Lbl", "Keyword": "" },
  { "Code": "Radiator_CoCurrent_Lbl", "Keyword": "" },
  { "Code": "Radiator_Heatexchanger_Lbl", "Keyword": "" },
  { "Code": "RefToAir_Heatexchanger_Lbl", "Keyword": "" },
  { "Code": "AirToAir_Heatexchanger_Lbl", "Keyword": "" },
  { "Code": "AirToAir_AbsolutePressure_Lbl", "Keyword": "" },
  { "Code": "AirToAir_MaxAirPressureDrop_Lbl", "Keyword": "" },
  { "Code": "Two-Stage_Heatexchanger_Lbl", "Keyword": "" },
  { "Code": "Radiator_HeatLoad_Lbl", "Keyword": "" },
  { "Code": "Two-Stage_HeatLoad_Lbl", "Keyword": "" },
  { "Code": "Radiator_InTemp1_Lbl", "Keyword": "fluidProperties" },
  { "Code": "Two-Stage_InTemp1_Lbl", "Keyword": "fluidProperties" },
  { "Code": "AirToAir_InletTemperature_Lbl", "Keyword": "fluidProperties" },
  { "Code": "AirToAir_AirFlow_Lbl", "Keyword": "" },
  { "Code": "Radiator_OutTemp1_Lbl", "Keyword": "warnings" },
  { "Code": "Two-Stage_OutTemp1_Lbl", "Keyword": "warnings" },
  { "Code": "AirToAir_OutletTemperature_Lbl", "Keyword": "warnings" },
  { "Code": "Radiator_Channel_Lbl", "Keyword": "" },
  { "Code": "AirToAir_DewTemp_Lbl", "Keyword": "" },
  { "Code": "lblValueHeatLoad", "Keyword": "" },
  { "Code": "lblValueInTemp1", "Keyword": "" },
  { "Code": "lblValueInTemp2", "Keyword": "" },
  { "Code": "lblValueOutTemp1", "Keyword": "" },
  { "Code": "lblValueOutTemp2", "Keyword": "" },
  { "Code": "lblValueFlow1", "Keyword": "Flow" },
  { "Code": "Radiator_MDP1_Lbl", "Keyword": "" },
  { "Code": "lblValueMDP1", "Keyword": "" },
  { "Code": "lblValuePasses", "Keyword": "" },
  { "Code": "lblValuePlates", "Keyword": "" },
  { "Code": "AirToAir_Plates_Lbl", "Keyword": "" },
  { "Code": "lblValueOS", "Keyword": "" },
  { "Code": "lblValueFF", "Keyword": "" },
  { "Code": "lblChannelFlipDesign", "Keyword": "" },
  { "Code": "lblAuto", "Keyword": "" },
  { "Code": "System_AutoPerformance_Lbl", "Keyword": "" },
  { "Code": "lblValueXin1", "Keyword": "" },
  { "Code": "RefToAir_Xin_Lbl", "Keyword": "" },
  { "Code": "lblValueXin", "Keyword": "" },
  { "Code": "lblValueXout1", "Keyword": "" },
  { "Code": "RefToAir_Xout_Lbl", "Keyword": "" },
  { "Code": "lblValueXout", "Keyword": "" },
  { "Code": "lblValueCondTemp", "Keyword": "" },
  { "Code": "lblValueSubCooling", "Keyword": "" },
  { "Code": "lblValueTLiqExp", "Keyword": "" },
  { "Code": "RefToAir_TLiqExp_Lbl", "Keyword": "" },
  { "Code": "lblValueEvapTemp", "Keyword": "" },
  { "Code": "RefToAir_EvapTemp_Lbl", "Keyword": "" },
  { "Code": "lblValueSuperHeating", "Keyword": "" },
  { "Code": "RefToAir_SuperHeating_Lbl", "Keyword": "" },
  { "Code": "radBtnDesign", "Keyword": "singlePhase" },
  { "Code": "radBtnPerformance", "Keyword": "singlePhase" },
  { "Code": "radBtnRating", "Keyword": "singlePhase" },
  { "Code": "lblValueCondTemp", "Keyword": "" },
  { "Code": "lblValueEvapTemp", "Keyword": "" },
  { "Code": "RadiatorFluid", "Keyword": "" },
  { "Code": "HotWaterFluid", "Keyword": "" },
  { "Code": "Radiator_Flow1_Lbl", "Keyword": "" },
  { "Code": "Radiator_Passes_Lbl", "Keyword": "" },
  { "Code": "Radiator_Plates_Lbl", "Keyword": "" },
  { "Code": "Two-Stage_Flow1_Lbl", "Keyword": "" },
  { "Code": "71", "Keyword": "" },
  { "Code": "72", "Keyword": "" },
  { "Code": "Refrigerant", "Keyword": "" },
  { "Code": "73", "Keyword": "" },
  { "Code": "btnCalculate", "Keyword": "" },
  { "Code": "lblValueFlow1", "Keyword": "" },
  { "Code": "lblValueChan11", "Keyword": "" },
  { "Code": "AHRI001", "Keyword": "AHRIParallelUnitsWarning" },
  { "Code": "PSW001", "Keyword": "AHRIParallelUnitsWarning" },
  { "Code": "SinglePhase", "Keyword": "singlePhase" },
  { "Code": "Condenser", "Keyword": "" },
  { "Code": "Evaporator", "Keyword": "" },
  { "Code": "SinglePhaseDual", "Keyword": "" },
  { "Code": "CondenserDual", "Keyword": "" },
  { "Code": "EvaporatorDual", "Keyword": "" },
  { "Code": "SinglePhaseTwoStacks", "Keyword": "" },
  { "Code": "Cascade", "Keyword": "" },
  { "Code": "CondenserHeatPump", "Keyword": "" },
  { "Code": "EvaporatorHeatPump", "Keyword": "" },
  { "Code": "TwoStage", "Keyword": "twoStage" },
  { "Code": "AirDryer", "Keyword": "airDryer" },
  { "Code": "DistrictEnergy", "Keyword": "" },
  { "Code": "FluidEditor", "Keyword": "fluidEditor" }
  ];


