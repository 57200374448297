import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import ItemComponent, {existsWarning} from '../../../../WikiDialog/ItemComponent.tsx';
import IconButton from '@mui/material/IconButton';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import {Collapse} from '@mui/material';

function getSeverityLevel(message) {
  switch (message.messageType) {
    case "Success":
      return  "success";
    case "Information":
      return  "info";
    case "Warning":
      return  "warning";
    case "Error":
      return "error";
    default:
      return "info";
  }
}

const MessageRow = ({ message }) => {
  const [expanded, setExpanded] = React.useState(false);
  const severity = getSeverityLevel(message);

  return (
      <Alert
        sx={{ bgcolor: "background.paper", p: 0, m: 0}}
        variant="standard"
        severity={severity}
        action={existsWarning(message.code) && <IconButton
          sx={{ p: 0, m: 0}}
          aria-label="open message"
          color="inherit"
          size="small"
          onClick={() => setExpanded(!expanded)}
        >
          <ExpandCircleDownOutlinedIcon fontSize="inherit" sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }} />
        </IconButton>}>
        {message.text}

        <Collapse in={expanded}>
          <ItemComponent code={message.code}/>
        </Collapse>
      </Alert>
  );
};

MessageRow.propTypes = {
  message: PropTypes.shape({
    code: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    messageType: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(MessageRow);
